<template>
	<div class="formList">
		<div class="hd">{{ label }}</div>
		<slot name="contslot"></slot>
	</div>
</template>

<script>
	export default {
		name: 'formList',
		props: {
			label: {
				type: String,
				default: ''
			},
		},
	}
</script>

<style scoped lang="less">
	.formList {
		margin-bottom: .2rem;
		.hd {
			color: #333333;
			font-size: .28rem;
			font-weight: bold;
		}
		.bd {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: .1rem;
			border-bottom: 1px solid #EDEDED;
		}
		.inp {
			padding: 5px 0;
			color: #333;
			font-size: .28rem;
			height: 0.6rem;
			display: block;
			width: 100%;
			flex: 1;
			border: 0;
			outline: 0;
		}
		input::-webkit-input-placeholder {
		    /* 修改字体颜色 */
		    color: #999999;
		    /* 修改字号，默认继承input */
		    font-size: 12px;
		}
		.button {
			line-height: .48rem;
			padding: 0 .15rem;
			border-radius: .04rem;
			border: 1px solid #90C4FF;
		}
	}
</style>
